import { Spring, animated, useSpring, easings} from 'react-spring'
import styled from 'styled-components'
import { useState } from 'react'


//create styled header component to make it look nice
const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 50px;`

const Background = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Eyes = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  transform: translateY(-60px);
  `

  

const App = () => {


  //useSpring to make the eyes move like a robot scanning left to right and up and down without useState

  const eyeSpring = useSpring({
    from: {
      transform: 'translateY(-60px)',
      opacity: 1,
    },
    to: async next => {
      while (1) {
        await next({
          transform: 'translateY(0px)',
          opacity: 1,
        })
        await next({
          transform: 'translateY(-10px)',
          opacity: 1,
        })
        await next({
          transform: 'translateY(0px)',
          opacity: 1,
        })
        await next({
          transform: 'translateY(10px)',
          opacity: 1,
        })
        await next({
          transform: 'translateY(0px)',
          opacity: 1,
        })
        await next({
          transform: 'translateX(0px)',
          opacity: 1,
        })
        await next({
          transform: 'translateX(15px)',
          opacity: 1,
        })
        await next({
          transform: 'translateX(0px)',
          opacity: 1,
        })
        await next({
          transform: 'translateX(-15px)',
          opacity: 1,
        })
        await next({
          transform: 'translateX(0px)',
          opacity: 1,
        })
      }
    },
    config: {
      mass: 1,
      tension: 120,
      friction: 20,
      clamp: true,
      precision: 0.001,
      easing: easings.easeInOut,
    }
  })










  return (
    <Background>
      <Eyes>
        <svg width="100" height="100">
          <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="white" />
          <circle cx="50" cy="50" r="20" stroke="black" stroke-width="3" fill="white" />
          <animated.circle cx="50" cy="50" r="10" stroke="black" stroke-width="3" fill="black" style={eyeSpring} />
        </svg>
        <svg width="100" height="100">
          <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="white" />
          <circle cx="50" cy="50" r="20" stroke="black" stroke-width="3" fill="white" />
          <animated.circle cx="50" cy="50" r="10" stroke="black" stroke-width="3" fill="black" style={eyeSpring} />
        </svg>
        </Eyes>

    <Spring
        loop
        from={{ opacity: 0, color: 'white' }}
        to={[
          { opacity: 1, color: 'red' },
          { opacity: 0, color: 'rgb(14,26,19)' },
          { opacity: 1, color: 'black' },
          { opacity: 0, color: 'rgb(14,26,19)' },
          { opacity: 1, color: 'white' },
        ]}>
        {styles => (
          <animated.div style={styles}> <Header>Welcome to AI</Header></animated.div>
        )}
      </Spring>
    </Background>
  )

}

export default App;